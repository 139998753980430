// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import { getLocalStorage } from './commonUtils'

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    //return false;
    const user = getLocalStorage('user');
    if (!user) {
        return false;
    } else {
        return true;
    }
    // const decoded = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // } else {
    //     return true;
    // }
};

/**
 * Returns the logged in user
 */
// const getLoggedInUser = () => {
//     const cookies = new Cookies();
//     const user = cookies.get('user');
//     return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
// };

export { isUserAuthenticated };
