/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */

import axios from "axios";

const fetchJSON = (url) => {
    return axios(url)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON };
