import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import FormAdvanced from '../pages/forms/Advanced';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// dashboard
const CRMDashboard = React.lazy(() => import('../pages/dashboards/CRM'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const CustomerList = React.lazy(() => import('../pages/apps/Customers/List'));
const CustomerCreateEdit = React.lazy(() => import('../pages/apps/Customers/CreateEdit.js'));
const CustomerView = React.lazy(() => import('../pages/apps/Customers/View.js'));

const ProjectList = React.lazy(() => import('../pages/apps/Projects/List.js'));
const ProjectCreateEdit = React.lazy(() => import('../pages/apps/Projects/CreateEdit.js'));
const ProjectView = React.lazy(() => import('../pages/apps/Projects/View.js'));

const TransactionList = React.lazy(() => import('../pages/apps/Transactions/List.js'));
const TransactionCreateEdit = React.lazy(() => import('../pages/apps/Transactions/CreateEdit.js'));
const TransactionView = React.lazy(() => import('../pages/apps/Transactions/View.js'));

// Connections
const ConnectionList = React.lazy(() => import('../pages/apps/Settings/ConnectionList'));
const ApplicationSettings = React.lazy(() => import('../pages/apps/Settings/ApplicationSettings'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));

// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));

// Products
const ProductList = React.lazy(() => import('../pages/apps/Products/List'));
const ProductAttributeList = React.lazy(() => import('../pages/apps/ProductAttributes/List'));

//Reports
const ARSalesReportWithProducts = React.lazy(() => import('../pages/apps/Reporting/ARSalesReportWithProducts'));
const CRMARReport = React.lazy(() => import('../pages/apps/Reporting/CRMARReport'));
const WorkOrderReports = React.lazy(() => import('../pages/apps/Reporting/workOrderReports'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            //const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            //if (roles && roles.indexOf(loggedInUser.role) === -1) {
            // role not authorised so redirect to home page
            //return <Redirect to={{ pathname: '/' }} />;
            //}

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboards',
    route: PrivateRoute,
    //roles: ['Admin'],
    // icon: 'uil-home-alt',
    component: CRMDashboard,
    header: 'Navigation',
};

const customerAppRoutes = {
    path: '/customers',
    name: 'Customers',
    route: PrivateRoute,
    //  roles: ['Admin'],
    icon: 'uil-calender',
    header: 'Customers',
    children: [
        {
            path: '/customers/leads',
            name: 'Leads',
            children: [
                {
                    path: '/customers/lead/list',
                    name: 'List All',
                    component: () => <CustomerList stage="lead" />,
                    route: PrivateRoute,
                },
                {
                    path: '/customers/lead/create',
                    name: 'Create New',
                    component: CustomerCreateEdit,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/customers/prospect',
            name: 'Prospects',
            children: [
                {
                    path: '/customers/prospect/list',
                    name: 'List All',
                    component: () => <CustomerList stage="prospect" />,
                    route: PrivateRoute,
                },
                {
                    path: '/customers/prospect/create',
                    name: 'Create New',
                    component: CustomerCreateEdit,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/customers/customer',
            name: 'Customers',
            children: [
                {
                    path: '/customers/customer/list',
                    name: 'List All',
                    component: () => <CustomerList stage="customer" />,
                    route: PrivateRoute,
                },
                {
                    path: '/customers/customer/create',
                    name: 'Create New',
                    component: CustomerCreateEdit,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/customers/customer',
            name: 'Projects',
            children: [
                {
                    path: '/project/list',
                    name: 'List All',
                    component: ProjectList,
                    route: PrivateRoute,
                },
            ],
        },
    ],
};

const transactionAppRoutes = {
    path: '/transactions',
    name: 'Transactions',
    route: PrivateRoute,
    // roles: ['Admin'],
    icon: 'uil-calender',
    header: 'Transactions',
    children: [
        {
            path: '/transactions/estimates',
            name: 'Estimates',
            children: [
                {
                    path: '/transactions/estimate/list',
                    name: 'List All',
                    component: () => <TransactionList stage="Estimate" />,
                    route: PrivateRoute,
                },
                {
                    path: '/transactions/estimates/create',
                    //name: 'Create New',
                    component: (props) => <TransactionCreateEdit stage="Estimate" {...props} />,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/transactions/workorders',
            name: 'Work Orders',
            children: [
                {
                    path: '/transactions/workorder/list',
                    name: 'List All',
                    component: () => <TransactionList stage="Work order" />,
                    route: PrivateRoute,
                },
                {
                    path: '/transactions/workorder/create',
                    //name: 'Create New',
                    component: (props) => <TransactionCreateEdit stage="Work order" {...props} />,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/transactions/invoices',
            name: 'Invoices',
            children: [
                {
                    path: '/transactions/invoice/list',
                    name: 'List All',
                    component: () => <TransactionList stage="Invoice" />,
                    route: PrivateRoute,
                },
                {
                    path: '/transactions/invoices/create',
                    //name: 'Create New',
                    component: (props) => <TransactionCreateEdit stage="Invoice" {...props} />,
                    route: PrivateRoute,
                },
            ],
        },
    ],
};

const productAppRoutes = {
    path: '/products',
    name: 'Products',
    route: PrivateRoute,
    icon: '',
    header: 'Products',
    children: [
        {
            path: '/products',
            name: 'Products',
            children: [
                {
                    path: '/products/list',
                    name: 'List All',
                    component: ProductList,
                    route: PrivateRoute,
                },

            ],
        },
        {
            path: '/productAttributes',
            name: 'ProductAttributes',
            children: [
                {
                    path: '/productAttributes/list',
                    name: 'List All',
                    component: ProductAttributeList,
                    route: PrivateRoute,
                },

            ],
        },
    ],

};
const jobcostingAppRoutes = {
    path: '/costing',
    name: 'Job Costing',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-store',
    children: [
        {
            path: '/costing/jobcosting',
            name: 'Job Costing',
            component: EcommerceProducts,
            route: PrivateRoute,
        },
        {
            path: '/costing/payrolldata',
            name: 'Payroll Data',
            component: ProductDetails,
            route: PrivateRoute,
        },
    ],
};

const scheduleAppRoutes = {
    path: '/schedule',
    name: 'Schedule',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-envelope',
    children: [
        {
            path: '/schedule/link1',
            name: 'Link 1',
            component: FormAdvanced,
            route: PrivateRoute,
        },
        {
            path: '/schedule/link2',
            name: 'Link 2',
            component: EmailDetail,
            route: PrivateRoute,
        },
    ],
};

const reportAppRoutes = {
    path: '/reporting',
    name: 'Reporting',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-briefcase',

    children: [
        {
            path: '/report/ARSalesReportWithProduct',
            name: 'AR Sales Report With Product',
            roles: ['Admin'],
            component: ARSalesReportWithProducts,
            route: PrivateRoute,
        },
        {
            path: '/report/CRMARReport',
            name: 'CRM AR Report',
            roles: ['Admin'],
            component: CRMARReport,
            route: PrivateRoute,
        },
        {
            path: '/report/WorkOrderReports',
            name: 'Work Order Reports',
            roles: ['Admin'],
            component: WorkOrderReports,
            route: PrivateRoute,
        }
        // {
        //     path: '/reporting/costing',
        //     name: 'Costing',
        //     children: [
        //         {
        //             path: '/reporting/costing/1',
        //             name: 'Report 1',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //         {
        //             path: '/reporting/costing/2',
        //             name: 'Report 2',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //         {
        //             path: '/reporting/costing/3',
        //             name: 'Report 3',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //     ],
        // },
        // {
        //     path: '/reporting/other',
        //     name: 'Other',
        //     children: [
        //         {
        //             path: '/reporting/other/1',
        //             name: 'Report 1',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //         {
        //             path: '/reporting/other/2',
        //             name: 'Report 2',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //         {
        //             path: '/reporting/other/3',
        //             name: 'Report 3',
        //             component: ProjectList,
        //             route: PrivateRoute,
        //         },
        //     ],
        // },
    ],
};


const settingsAppRoutes = {
    path: '/settings',
    name: 'Settings',
    route: PrivateRoute,
    // roles: ['Admin'],
    icon: 'uil-rss',

    children: [
        {
            path: '/settings/connections',
            name: 'Connections',
            component: ConnectionList,
            route: PrivateRoute,
        },
        //     {
        //         path: '/settings/lists',
        //         name: 'Lists',
        //         children: [
        //             {
        //                 path: '/settings/costing',
        //                 name: 'Costing Items',
        //                 component: ProjectList,
        //                 route: PrivateRoute,
        //             },
        //             {
        //                 path: '/settings/employees',
        //                 name: 'Employees',
        //                 component: ProjectList,
        //                 route: PrivateRoute,
        //             },
        //             {
        //                 path: '/settings/transactionitems',
        //                 name: 'Estimate Items',
        //                 component: ProjectList,
        //                 route: PrivateRoute,
        //             },
        //         ],
        //     },
        {
            path: '/settings/applicationSettings',
            name: 'App Settings',
            roles: ['Admin'],
            component: ApplicationSettings,
            route: PrivateRoute,
        },
        // {
        //     path: '/settings/jobtypes',
        //     name: 'Job Types',
        //     component: ProjectList,
        //     route: PrivateRoute,
        // },
        // {
        //     path: '/settings/services',
        //     name: 'Services',
        //     component: ProjectList,
        //     route: PrivateRoute,
        // },
        // {
        //     path: '/settings/trucks',
        //     name: 'Trucks',
        //     component: ProjectList,
        //     route: PrivateRoute,
        // },
    ],
};



const appRoutes = [
    customerAppRoutes,
    transactionAppRoutes,
    productAppRoutes,
    // jobcostingAppRoutes,
    // scheduleAppRoutes,
    reportAppRoutes,
    settingsAppRoutes,
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

const miscRoutes = [
    {
        path: '/customers/edit',
        name: 'Customer Edit',
        component: CustomerCreateEdit,
        route: PrivateRoute,
    },
    {
        path: '/customers/view',
        name: 'Customer View',
        component: CustomerView,
        route: PrivateRoute,
    },
    {
        path: '/transactions/view',
        name: 'Transaction View',
        component: TransactionView,
        route: PrivateRoute,
    },
    {
        path: '/transactions/edit',
        name: 'Transaction Edit',
        component: TransactionCreateEdit,
        route: PrivateRoute,
    },
    {
        path: '/projects/create',
        name: 'Create New',
        component: ProjectCreateEdit,
        route: PrivateRoute,
    },
    {
        path: '/projects/edit',
        name: 'Project Edit',
        component: ProjectView,
        route: PrivateRoute,
    },
    {
        path: '/projects/view',
        name: 'Project View',
        component: ProjectView,
        route: PrivateRoute,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];
    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);
        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, ...miscRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
